<template>
  <v-col cols="12">
    <v-row>
      <template v-if="!hideDoc">
        <v-col cols="12" lg="4" xl="2">
          <select-doc-type
            v-model="sDocType"
            :country-id="obFirm.country_id"
            :disabled="endCustomer"
            :required="!endCustomer"
            :tax-exempt="taxExempt"
          />
        </v-col>

        <v-col cols="12" lg="4" xl="3">
          <form-field-doc-id
            v-model="obFirmModel.doc_id"
            :disabled="endCustomer"
            :doc-type="sDocType"
            :required="!endCustomer"
            @load="setFirmData"
          />
        </v-col>
      </template>

      <template v-if="!hideBasics">
        <v-col :xl="hideDoc ? undefined : 3" cols="12" lg="4">
          <form-field-text
            v-model="obFirmModel.name"
            label="fantasy.name"
            required
            @blur="onBlurName"
          />
        </v-col>

        <v-col
          v-if="!hideLegalName"
          :xl="hideDoc ? undefined : 2"
          cols="12"
          lg="4"
        >
          <form-field-text
            v-model="obFirmModel.dgi_denominacion"
            label="legal.name"
            required
          />
        </v-col>

        <v-col :lg="hideDoc ? 4 : 3" :xl="hideDoc ? undefined : 2" cols="12">
          <code-field-text
            v-model="obFirm.code"
            :item-key="codeKey"
            :show-code-button="!obFirm.code"
            label="code"
            required
          />
        </v-col>
      </template>

      <template v-if="location">
        <v-col cols="12" xl="6">
          <firm-field-address :firm="obFirmModel" :required="!endCustomer" />
        </v-col>

        <v-col cols="12" xl="6">
          <form-field-text
            v-model="obFirmModel.address2"
            label="secondary.address"
          />
        </v-col>

        <v-col cols="12" lg="4" md="6">
          <form-field-country
            v-model="obFirmModel.country_id"
            :codes="validCountryCodes"
            :required="!endCustomer"
          />
        </v-col>

        <v-col cols="12" lg="4" md="6">
          <firm-field-state :firm="obFirmModel" :required="!endCustomer" />
        </v-col>

        <v-col cols="12" lg="4" md="6">
          <firm-field-town :firm="obFirmModel" :required="!endCustomer" />
        </v-col>
      </template>

      <template v-if="allFields && !endCustomer">
        <v-col cols="12" lg="4" md="6">
          <form-field-email v-model="obFirmModel.email" multiple />
        </v-col>
        <v-col cols="12" lg="4" md="6">
          <form-field-text v-model="obFirmModel.phone" label="phone" />
        </v-col>
        <v-col cols="12" lg="4" md="6">
          <form-field-text v-model="obFirmModel.mobile" label="mobile" />
        </v-col>
      </template>

      <v-col v-if="!hideDescription" cols="12">
        <v-textarea
          v-model="obFirmModel.description"
          :label="$t('description')"
          outlined
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script lang="ts">
import { Firm, FirmData } from "@planetadeleste/vue-mc-gw";
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { toCode } from "@/plugins/helpers";

import SelectDocType from "@/components/form/fields/SelectDocType.vue";
import FormFieldCountry from "@/components/form/fields/Country.vue";
import FormFieldState from "@/components/form/fields/State.vue";
import FormFieldTown from "@/components/form/fields/Town.vue";
import FormFieldDocId from "@/components/form/fields/DocidText.vue";
import Utils, { IDListKey } from "@/services/Utils";
import { isEmpty } from "lodash";
import CodeFieldText from "@/components/form/fields/CodeFieldText.vue";
import FirmFieldState from "@/components/form/fields/FirmFieldState.vue";
import FirmFieldTown from "@/components/form/fields/FirmFieldTown.vue";
import FirmFieldAddress from "@/components/form/fields/FirmFieldAddress.vue";

@Component({
  components: {
    FirmFieldAddress,
    FirmFieldTown,
    FirmFieldState,
    CodeFieldText,
    SelectDocType,
    FormFieldState,
    FormFieldTown,
    FormFieldDocId,
    FormFieldCountry,
  },
})
export default class FirmForm extends Vue {
  @VModel({ type: [Object, Array], default: () => ({}) })
  obFirm!: Firm | Partial<FirmData>;

  @Prop(Boolean) readonly allFields!: boolean;
  @Prop(Boolean) readonly location!: boolean;
  @Prop(Boolean) readonly endCustomer!: boolean;
  @Prop(Boolean) readonly taxExempt!: boolean;
  @Prop(Boolean) readonly hideDoc!: boolean;
  @Prop(Boolean) readonly hideBasics!: boolean;
  @Prop(Boolean) readonly hideDescription!: boolean;
  @Prop(Boolean) readonly hideLegalName!: boolean;
  @Prop(String) readonly codeKey!: string;

  obFirmModel: Firm = new Firm();

  get validCountryCodes() {
    if (!this.sDocType) {
      return [];
    }

    return Utils.validateCountryByID(this.sDocType as IDListKey);
  }

  get sDocType() {
    return this.obFirmModel.get("doc_type", "");
  }

  set sDocType(sValue: string) {
    this.obFirmModel.set("doc_type", sValue);
  }

  setFirmData(obData: Partial<FirmData>) {
    this.obFirmModel.assign(obData);

    this.onEmit(this.obFirmModel.attributes);
  }

  onEmit(obData: Partial<FirmData>) {
    this.$emit("input", obData);
  }

  onBlurName() {
    if (isEmpty(this.obFirmModel.name) || !this.obFirmModel.name) {
      return;
    }

    if (!this.obFirmModel.code) {
      this.obFirmModel.set("code", toCode(this.obFirmModel.name));
    }

    this.obFirmModel.set("dgi_denominacion", this.obFirmModel.name);
    this.onEmit(this.obFirmModel.attributes);
  }

  created() {
    const obData =
      this.obFirm instanceof Firm ? this.obFirm.attributes : this.obFirm;
    this.obFirmModel.set(obData);
    this.obFirmModel.on("change", () => {
      this.onEmit(this.obFirmModel.attributes);
    });
  }
}
</script>
